export function getPortalName(portals, portalId, clients, tags, categories, addPrefix = false) {
    const portal = portals.find(p => p.client_portal_id === portalId);
    if (!portal) return null;
    switch (portal.target) {
        case 'default':
            return 'Default Portal';
        case 'tag':
            const tag = tags.find(t => t.value === portal.target_value);
            return `Tag: ${tag?.label} ${addPrefix ? 'Portal' : ''}`;
        case 'category':
            const category = categories.find(c => c.value === portal.target_value);
            return `Industry: ${category?.label} ${addPrefix ? 'Portal' : ''}`;
        case 'client':
            const client = clients.find(c => c.value === portal.target_value);
            if (!client) return 'loading...';
            return `${client?.label} ${addPrefix ? 'Portal' : ''}`;
        default:
            return 'Unknown Portal';
    }
};