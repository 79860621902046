import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Popup, TabBar, Tab, Input, LabeledCheckbox } from '@commonsku/styles';
import { Select, AsyncSelect } from '../../helpers';
import { addPortal, updatePortal, portalSelectors, defaultPortalData } from '../../../redux/clientPortals';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oauth } from '../../../utils';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import PortalClients from './PortalClients';
import PortalHeroBannerTemplate from './PortalHeroBannerTemplate';
import { getPortalName } from '../../helpers/ClientPortal';
import LabelledColorPicker from '../../LabelledColorPicker';
import RichTextEditor from '../../helpers/RichTextEditor';

const findExistingPortal = (portals, portalData, portalId) => {
    const existingPortal = portals.find(p => p.target === portalData.target && (portalData.target === 'default' || p.target_value === portalData.target_value));
    if (existingPortal && existingPortal.client_portal_id !== portalId) {
        return existingPortal;
    }

    return false;
};

export default function PortalPopup({ onClosePopup, portalId = null, industry_options, tag_options, clients, shops, showAnotherPortalPopup, chatBots, initTabIndex = 0, hasAccess }) {
    const clientPortalState = useSelector(state => state.clientPortals);
    const portals = portalSelectors.selectAll(clientPortalState);
    const currentPortal = portalId ? portalSelectors.selectById(clientPortalState, portalId) : null;
    const [portalData, setPortalData] = useState(portalId ? currentPortal : defaultPortalData(portals.length === 0));

    const [clientOptions, setClientOptions] = useState(clients.map(c => ({ label: c.client_name, value: c.client_id })));

    let botOptions = chatBots.map(b => ({
        label: b.name,
        value: b.bot_id,
    }));
    botOptions = [{value: '', label: '--- Disable Chat ---'}, ...botOptions];

    const [selectedTabIndex, setSelectedTabIndex] = useState(initTabIndex);

    const [targetValueError, setTargetValueError] = useState(false);

    const validatePortalData = useCallback((currentData) => {
        if (currentData.target !== 'default' && (!currentData.target_value || currentData.target_value.length === 0)) {
            setTargetValueError(true);
            toast.error("Please select a value for " + currentData.target);
            return false;
        }

        return true;
    }, []);

    const dispatch = useDispatch();
    const addNewPortal = useCallback(() => {
        const data = portalData;
        if (!validatePortalData(data)) return;

        dispatch(addPortal(data));
        onClosePopup();
        toast.success("Added successfully");
    }, [dispatch, onClosePopup, portalData, validatePortalData]);

    const updateExistingPortal = useCallback(() => {
        const data = portalData;
        if (!validatePortalData(data)) return;

        dispatch(updatePortal(data));
        onClosePopup();
        toast.success("Saved successfully");
    }, [dispatch, onClosePopup, portalData, validatePortalData]);

    const onFormElementChange = useCallback((e) => {
        const target = e.target;
        // Normal input
        if (target) {
            let clearTargetValue = false;
            if (target.name === 'target') {
                // Clear target value if target changes
                clearTargetValue = true;
            }
            setPortalData(oldData => {
                return {
                    ...oldData,
                    [target.name]: target.value,
                };
            });

            if (clearTargetValue) {
                setPortalData(oldData => {
                    return {
                        ...oldData,
                        target_value: null,
                    };
                });
            }
        }
    }, []);

    const onAddImage = useCallback((imageData) => {
        setPortalData(oldData => {
            const oldImages = oldData.images ? oldData.images.split(',') : [];
            oldImages.push(imageData.file_name_original);
            return {
                ...oldData,
                images: oldImages.join(',')
            };
        });
    }, []);

    const onRemoveImage = useCallback((imageUrl) => {
        setPortalData(oldData => {
            const old = oldData.images ? oldData.images.split(',') : [];
            const newImages = old.filter(i => i !== imageUrl);
            return {
                ...oldData,
                images: newImages.join(',')
            };
        });
    }, []);

    const onMoveImage = useCallback((imageUrl, moveUp = false) => {
        setPortalData(oldData => {
            const oldImages = oldData.images ? oldData.images.split(',') : [];
            const index = oldImages.indexOf(imageUrl);
            let newImages = [];
            if (moveUp) {
                const firstHalf = oldImages.slice(0, index - 1);
                const secondHalf = oldImages.slice(index + 1);
                newImages = [...firstHalf, imageUrl, oldImages[index - 1], ...secondHalf];
            } else {
                const firstHalf = oldImages.slice(0, index);
                const secondHalf = oldImages.slice(index + 2);
                newImages = [...firstHalf, oldImages[index + 1], imageUrl, ...secondHalf];
            }
            return {
                ...oldData,
                images: newImages.join(',')
            };
        });
    }, []);

    const onAddShop = useCallback((shop) => {
        setPortalData(oldData => {
            const oldShops = oldData.shops ? oldData.shops.split(',') : [];
            oldShops.push(shop.shop_id);
            return {
                ...oldData,
                shops: uniq(oldShops).join(',')
            };
        });
    }, []);

    const onRemoveShop = useCallback((shopId) => {
        setPortalData(oldData => {
            const oldShops = oldData.shops ? oldData.shops.split(',') : [];
            const newShops = oldShops.filter(s => s !== shopId);
            return {
                ...oldData,
                shops: newShops.join(',')
            };
        });
    }, []);

    const onMoveShop = useCallback((shopId, moveUp = false) => {
        setPortalData(oldData => {
            const oldShops = oldData.shops ? oldData.shops.split(',') : [];
            const index = oldShops.indexOf(shopId);
            let newShops = [];
            if (moveUp) {
                const firstHalf = oldShops.slice(0, index - 1);
                const secondHalf = oldShops.slice(index + 1);
                newShops = [...firstHalf, shopId, oldShops[index - 1], ...secondHalf];
            } else {
                const firstHalf = oldShops.slice(0, index);
                const secondHalf = oldShops.slice(index + 2);
                newShops = [...firstHalf, oldShops[index + 1], shopId, ...secondHalf];
            }
            return {
                ...oldData,
                shops: newShops.join(',')
            };
        });
    }, []);

    const onToggleClientSpecificShop = useCallback((e) => {
        setPortalData(oldData => {
            return {
                ...oldData,
                show_client_specific: oldData.show_client_specific == 1 ? 0 : 1,
            };
        });
    }, []);

    const onToggleRestrictToUserProjects = useCallback((e) => {
        setPortalData(oldData => {
            return {
                ...oldData,
                restrict_to_user_projects: oldData.restrict_to_user_projects == 1 ? 0 : 1,
            };
        });
    }, []);

    // Async select to load options for clients
    const loadClients = useCallback(async (searchValue) => {
        const clients = await oauth('GET', 'company', {
            company_type: 'CLIENT',
            search: true,
            'max-results': 50,
            company_name: searchValue
        });
        const options = clients.json.companies.map(c => ({ label: c.client_name, value: c.client_id }));
        setClientOptions((oldOptions) => {
            const allOptions = [...oldOptions, ...options];
            return uniqBy(allOptions, 'value');
        });
        return options;
    }, []);

    // For existing portal, we will need to load some existing client options
    // To populate the async select
    const loadClientById = useCallback(async (clientId) => {
        if (clientOptions.find(c => c.value === clientId)) return;
        // console.log('Load a single client since we couldnot find it in the list of clients');
        const client = await oauth('GET', 'company/get', {
            company_type: 'CLIENT',
            id: clientId
        });
        const newOption = {
            label: client.json.company.client_name,
            value: client.json.company.client_id,
        };
        setClientOptions((oldOptions) => {
            const allOptions = [...oldOptions, newOption];
            return uniqBy(allOptions, 'value');
        });
    }, []);

    useEffect(() => {
        if (currentPortal && currentPortal.target === 'client') {
            loadClientById(currentPortal.target_value);
        }
    }, [currentPortal, loadClientById]);

    const popupTitle = !currentPortal ? 'New Portal' : getPortalName(portals, portalId, clientOptions, tag_options, industry_options);
    const existingPortal = findExistingPortal(portals, portalData, portalId);

    const restrictToUserProjectsToggle = (
        <LabeledCheckbox
            checked={portalData.restrict_to_user_projects == 1}
            onChange={onToggleRestrictToUserProjects}
        />
    );

    const chatBotSelect = (
        <Select
            name="chat_bot_id"
            value={portalData.chat_bot_id || ''}
            options={botOptions}
            onChange={(option) => { onFormElementChange({ target: { name: 'chat_bot_id', value: option.value } }); }}
            inPopup
        />
    );

    const configuration = (
        <>
            <Row style={{ marginTop: '2rem' }}>
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Audience</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    {((portals.length > 0) && portalData?.target !== 'default') && <Row>
                        <Col sm={4}>
                            <input type="radio" name="target" value="client" checked={portalData.target === 'client'} onChange={onFormElementChange} />
                            <label>Single Client</label>
                        </Col>
                        <Col sm={4}>
                            <input type="radio" name="target" value="category" checked={portalData.target === 'category'} onChange={onFormElementChange} />
                            <label>Industry</label>
                        </Col>
                        <Col sm={4}>
                            <input type="radio" name="target" value="tag" checked={portalData.target === 'tag'} onChange={onFormElementChange} />
                            <label>Tag</label>
                        </Col>
                    </Row>}
                    {(portals.length === 0 || portalData.target === 'default') && <Row>
                        <Col sm={4}>
                            <input type="radio" name="target" value="default" checked={portalData.target === 'default'} onChange={onFormElementChange} />
                            <label>Default</label>
                        </Col>
                    </Row>}
                    <Row style={{marginTop: '1em'}}>
                        <Col sm={6}>
                            {portalData.target === 'client' &&
                                <AsyncSelect
                                    error={targetValueError}
                                    placeholder="Select a client"
                                    name="target_value"
                                    value={portalData.target_value}
                                    options={clientOptions}
                                    onChange={(option) => { onFormElementChange({ target: { name: 'target_value', value: option.value } }); }}
                                    inPopup
                                    loadOptions={loadClients}
                                />}
                            {portalData.target === 'category' &&
                                <Select
                                    error={targetValueError}
                                    placeholder="Select an industry"
                                    name="target_value"
                                    value={portalData.target_value}
                                    options={industry_options}
                                    onChange={(option) => { onFormElementChange({ target: { name: 'target_value', value: option.value } }); }}
                                    inPopup
                                />}
                            {portalData.target === 'tag' &&
                                <Select
                                    error={targetValueError}
                                    placeholder="Select a tag"
                                    name="target_value"
                                    value={portalData.target_value}
                                    options={tag_options}
                                    onChange={(option) => { onFormElementChange({ target: { name: 'target_value', value: option.value } }); }}
                                    inPopup
                                />}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {existingPortal && <Row className="option-row">
                <Col sm={12}>
                    <div className="info-block">
                        <span>There is already another portal defined for <b>
                            {getPortalName(portals, existingPortal.client_portal_id, clientOptions, tag_options, industry_options)}
                        </b></span>
                        <Button onClick={() => {
                            onClosePopup();
                            showAnotherPortalPopup(existingPortal); }}
                        >
                            Edit
                        </Button>
                    </div>
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Name (optional)</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    <div style={{maxWidth: '25em'}}>
                        <Input name="label" value={portalData?.label} placeholder="Customized name..." onChange={onFormElementChange} />
                    </div>
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Theme Color</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    <LabelledColorPicker
                        value={portalData?.theme_color}
                        onChange={(color) => { onFormElementChange({ target: { name: 'theme_color', value: color } }); }}
                    />
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Restrict by Contact</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    {restrictToUserProjectsToggle}
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Chat Bot</label></div>
                </Col>
                <Col lg={6} sm={6} xs>
                    {chatBotSelect}
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Start Project Message</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    <div>
                        <RichTextEditor
                            value={portalData?.start_project_message}
                            onChange={(value) => { onFormElementChange({ target: { name: 'start_project_message', value: value } }); }}
                        />
                    </div>
                </Col>
            </Row>}
            {!existingPortal && <Row className="option-row">
                <Col lg={2} sm={3} xs>
                    <div><label style={{ fontWeight: 'bold' }}>Reorder Message</label></div>
                </Col>
                <Col lg={10} sm={9} xs>
                    <div>
                        <RichTextEditor
                            value={portalData?.reorder_message}
                            onChange={(value) => { onFormElementChange({ target: { name: 'reorder_message', value: value } }); }}
                        />
                    </div>
                </Col>
            </Row>}
        </>);

    const designTab = (
    <>
        {!existingPortal && <Row className="option-row">
            <Col lg={12} sm={12}>
                <PortalHeroBannerTemplate
                    client={portalData.target === 'client' && clients.filter(c => c.client_id === portalData.target_value)?.pop()}
                    bannerImages={portalData.images.split(',')}
                    shopIds={portalData.shops ? portalData.shops.split(',') : []}
                    allShops={shops}
                    isEditable={true}
                    onAddImage={onAddImage}
                    onRemoveImage={onRemoveImage}
                    onMoveImage={onMoveImage}
                    onAddShop={onAddShop}
                    onRemoveShop={onRemoveShop}
                    onMoveShop={onMoveShop}
                    showClientSpecific={portalData.show_client_specific}
                    onToggleClientSpecificShop={onToggleClientSpecificShop}
                    singleShop={+portalData.simple === 1}
                    themeColor={portalData.theme_color}
                    portalLogo={portalData.portal_logo}
                    onLogoChange={onFormElementChange}
                />
            </Col>
        </Row>}
    </>);

    const whoUseIt = (
        <div><PortalClients portal={currentPortal} clients={clientOptions} hasAccess={hasAccess} /></div>
    );

    const tabs = [
        {
            label: 'Settings',
            content: configuration,
        },
        {
            label: 'Design',
            content: designTab,
        }
    ];

    if (currentPortal) {
        tabs.push({
            label: 'Who uses it',
            content: whoUseIt,
        });
    }

    const content = (
        <div>
            <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                {tabs.map((tab, index) => <Tab
                    key={'tabs_' + index} size={tabs.length}
                    selected={index === selectedTabIndex}
                    onClick={() => {
                        setSelectedTabIndex(index);
                    }}>
                    {tab.label}
                </Tab>)}
            </TabBar>
            {tabs[selectedTabIndex] ? tabs[selectedTabIndex].content : null}
        </div>
    );

    return (
        <Popup style={{ padding: 20, maxWidth: '960px' }} width='calc(100vw - 100px)' height='calc(100vh - 100px)' header={
            <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                <h2 style={{ margin: '0.2em' }}>{popupTitle}</h2>
                <div>
                    <Button secondary onClick={onClosePopup}>Cancel</Button>
                    {' '}
                    {!currentPortal && !existingPortal && <Button onClick={addNewPortal}>Create</Button>}
                    {currentPortal && !existingPortal && <Button onClick={updateExistingPortal}>Update</Button>}
                </div>
            </Row>
        }>
            {content}
        </Popup>
    );
}
