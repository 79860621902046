import React from 'react';
import { Button, Dropzone } from '@commonsku/styles';

const DEFAULT_COLOR = '#5ca3b6';

export default function Category({category, sortFirst, sortLast, isLast, onUploadFile, isEditable, onRemoveShop, onMoveUp, onMoveDown, singleShopId, client, themeColor, blur}) {
    const template_color = themeColor ? themeColor : DEFAULT_COLOR;
    const isShop = category.shopData;

    if (isShop) {
        if (category.shopData.status_name === 'Closed') return null;
        if (category.shopData.date_expiry) {
            const expired = Date.parse(category.shopData.date_expiry);
            if (Date.now() > expired ) return null;
        }
    }
    const isClientShop = category.shopData.type !== 'Marketing';
    return (
        <div className={`product-column small-12 medium-4 columns ${isLast ? 'end' : ''}`}>
            <div id="feat-prod" onClick={(e) => {
                if (blur) return;
                const uri = isShop ? `/shop/${category.shopData.shop_id}` : `/shop/${singleShopId}`;
                window.open(`${uri}?client_id=${client.client_id}`, '_blank');
            }}>
                <div className="prod-img-container">
                    {isEditable && isShop &&
                    <div className="portal-shop-actions">
                        <div className="shop-upload-image">
                            <Dropzone onDrop={onUploadFile} style={{ border: 'none', display: 'inline-block' }} multiple={false} accept="image/*">
                                <Button style={{ marginRight: ' 1rem' }} onClick={() => { }}>Change Image</Button>
                            </Dropzone>
                        </div>
                        {!isClientShop && <div>
                            <Button style={{ marginRight: ' 1rem' }} onClick={onRemoveShop}>&times;</Button>
                        </div>}
                    </div>
                    }
                    <div className={"prod-img"}
                        style={{ backgroundImage: `url('${category.image}')`, backgroundRepeat: 'no-repeat', textAlign: 'center' }}
                    >
                    </div>
                    <div className="label" style={{color: template_color}}>{category && category.name}</div>
                </div>
            </div>
        </div>
    );
};