import React, { useCallback } from 'react';
import { Button, Dropzone } from '@commonsku/styles';
import 'react-toastify/dist/ReactToastify.css';
import { createUploadFile } from '../../../actions/file';
import { useDispatch } from 'react-redux';

export default function PortalImages({ images, onAddImage, onRemoveImage, onMoveImage }) {
    const dispatch = useDispatch();

    const onMoveImageInternal = useCallback((url, moveUp = true) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        onMoveImage(url, moveUp);
    }, []);

    const imageList = images.map((i, index) => {
        const sortFirst = index === 0;
        const sortLast = index === images.length - 1;
        return (
            <div className="portal-image-edit-container" key={i}>
                <div className="portal-shop-actions">
                    {!sortFirst && <div>
                        <Button style={{ marginRight: ' 1rem' }} onClick={onMoveImageInternal(i, true)}>&#8593;</Button>
                    </div>}
                    {!sortLast && <div>
                        <Button style={{ marginRight: ' 1rem' }} onClick={onMoveImageInternal(i, false)}>&#8595;</Button>
                    </div>}
                    <Button style={{ marginRight: ' 1rem' }} onClick={() => {onRemoveImage(i);}}>&times;</Button>
                </div>
                <img src={i} key={i} alt={i} />
            </div>
        );
    });

    const onUploadFile = useCallback((files) => {
        dispatch(createUploadFile('PORTAL', 'PORTAL', files[0])).then(
            action => onAddImage(action.payload.data)
        );
    }, [dispatch, onAddImage]);

    return (
        <>
            {imageList}
            <Dropzone onDrop={onUploadFile} style={{ border: 'none', display: 'inline-block' }} multiple={false} accept="image/*">
                <Button secondary style={{ marginRight: '1rem', padding: '0.5em 1em' }} onClick={() => { }}>+ Add New</Button>
            </Dropzone>
        </>
    );
}