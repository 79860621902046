import React, { useCallback } from 'react';
import { Button, Dropzone } from '@commonsku/styles';
import 'react-toastify/dist/ReactToastify.css';
import { createUploadFile } from '../../../actions/file';
import { useDispatch, useSelector } from 'react-redux';

export default function PortalLogo({ image, onLogoChange }) {
    const dispatch = useDispatch();
    const identity = useSelector(state => state.identity);

    const onUploadFile = useCallback((files) => {
        dispatch(createUploadFile('PORTAL', 'PORTAL', files[0])).then(
            action => onLogoChange({ target: { name: 'portal_logo', value: action.payload.data.file_name_original } })
        );
    }, [dispatch, onLogoChange]);

    let displayImage = image;
    if (!image) {
        displayImage = identity.company_avatar.medium;
    }

    return (
        <div style={{position: 'relative', width: '100px', height: '100px'}}>
            <div className="portal-shop-actions">
                <div className="shop-upload-image">
                    <Dropzone onDrop={onUploadFile} style={{ border: 'none', display: 'inline-block' }} multiple={false} accept="image/*">
                        <Button style={{ marginRight: ' 1rem' }} onClick={() => { }}>Change</Button>
                    </Dropzone>
                </div>
            </div>
            <img src={displayImage} style={{width: '100px', height: '100px'}} alt="portal logo" />
        </div>
    );
}