import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input } from '@commonsku/styles';
import 'react-toastify/dist/ReactToastify.css';
import { oauth } from '../../../utils';
import { debounce } from 'lodash';
import { portalSelectors } from '../../../redux/clientPortals';
import { useSelector } from 'react-redux';

export default function PortalClients({ clients, portal, hasAccess }) {
    const portalState = useSelector(state => state.clientPortals);
    const portals = portalSelectors.selectAll(portalState);
    const clientSpecificPortals = portals.map(p => {
        if (p.target === 'client') {
            return p.target_value;
        }
        return null;
    });

    const [portalClients, setPortalClients] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchTextForm, setSearchTextForm] = useState('');

    const search = useMemo(() => {
        const conditions = {
            company_type: 'CLIENT',
            'start-index': 0,
            'max-results': 50,
            'order-by': 'latest_use',
            'order-dir': 'desc',
            search: true,
            with_sales_report: false,
            company_name: searchText,
            tag_id: '', industry_id: '', sales_rep_id: '', account_status_id: ''
        };

        if (portal.target === 'tag') {
            conditions.tags = [portal.target_value];
        }
        if (portal.target === 'category') {
            conditions.industry_id = portal.target_value;
        }

        return conditions;

    }, [portal.target, portal.target_value, searchText]);

    const getClients = useCallback(async () => {
        const clientData = await oauth('GET', 'company/clients', search);
        const clients = clientData.json.companies.filter(c => hasAccess(c.client_rep_user_id));
        setPortalClients(clients);
    }, [hasAccess, search]);

    const getClientPortal = useCallback(async (clientId) => {
        await oauth('GET', 'client-portal-admin/get-client-portal', { client_id: clientId });
    }, []);

    useEffect(() => {
        if (portal.target === 'default' || portal.target === 'tag' || portal.target === 'category') {
            getClients();
        }
    }, [getClients, portal.target]);

    const debouncedSetSearch = debounce((text) => {
        setSearchText(text);
    }, 1000);

    const updateSearch = useCallback((e) => {
        setSearchTextForm(e.target.value);
        debouncedSetSearch(e.target.value);
    }, []); // We shouldn't set any dependancy here to avoid multiple debounced callbacks

    if (portal.target === 'client') {
        const client = clients.find(c => c.value === portal.target_value);
        if (!client) {
            return null;
        }
        return <div className="portal-client-row">
            <span>{client.label}</span>
            <Button onClick={() => {
                getClientPortal(client.value);
                window.open(`/client/${client.value}/portal/`, '_blank');
            }}>View Portal</Button>
        </div>;
    }

    return (
        <>
            {portal.target === 'default' && <p style={{paddingLeft: '1em', paddingTop: '1em'}}>Default for all clients.</p>}
            <Input style={{maxWidth: '300px', marginLeft: '1em', marginTop: '1em'}} name="label" value={searchTextForm} placeholder="Search" onChange={updateSearch} />
            {portalClients.filter(c => clientSpecificPortals.indexOf(c.client_id) === -1 ).map(c => (
                <div key={c.client_id} className="portal-client-row">
                    <span>{c.client_name}</span>
                    <Button onClick={() => {
                        getClientPortal(c.client_id);
                        window.open(`/client/${c.client_id}/portal/`, '_blank');
                    }}>View Portal</Button>
                </div>
            ))}
        </>
    );
}