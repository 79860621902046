import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ImageGallery from '../../ImageGallery';
import PortalImages from './PortalImages';
import PortalAddShop from './PortalAddShop';
import PortalCategory from './PortalCategory';
import PortalLogo from './PortalLogo';
import { Row, Col, Button, LabeledCheckbox } from '@commonsku/styles';
import { oauth, getImageSrc } from '../../../utils';
import { getImagesFromSingleShop, getCategoriesFromSingleShop, getCategoriesFromShops } from '../../../helpers/shop';
import { createUploadFile } from '../../../actions/file';
import { finalizeUpdateShopFlatArray } from '../../../actions/shop';

const loadedImages = [];

export default function PortalHeroBannerTemplate({
    bannerImages,
    shopIds,
    allShops,
    isEditable = false,
    onAddShop,
    onAddImage,
    onRemoveImage,
    onMoveImage,
    onRemoveShop,
    onMoveShop,
    showClientSpecific,
    onToggleClientSpecificShop,
    singleShop = false,
    client = false,
    themeColor = '',
    portalLogo = '',
    onLogoChange,
    blur = false,
}) {
    const dispatch = useDispatch();
    const [editingHero, setEditingHero] = useState(false);
    const [singleShopData, setSingleShopData] = useState(null);

    const firstShopId = shopIds[0];

    useEffect(() => {
        const getShopData = async (shopId) => {
            const shopData = await oauth('GET', 'page/view-shop', {
                object_id: shopId,
                public: true,
                buy_inventory: 0,
            });
            setSingleShopData(shopData.json.payload.entities);
        };

        if (singleShop) {
            getShopData(firstShopId);
        }
    }, [firstShopId, singleShop]);

    // Images
    let images = bannerImages.map((image) => ({
        original: image.length === 0 ? '/images/template3.jpg' : image,
        title: '',
    }));
    if (singleShop) {
        images = getImagesFromSingleShop(singleShopData, shopIds[0]);
    }
    useEffect(() => {
        const getShopItemImage = async (shopId) => {
            if (loadedImages.indexOf(shopId) !== -1) {
                return; // Already loaded
            }
            const shopImageData = await oauth('GET', 'shop/get-image', { shop_id: shopId });
            if (shopImageData?.json?.image) {
                const imageUrl = getImageSrc(shopImageData?.json?.image, 'medium');
                dispatch(finalizeUpdateShopFlatArray(shopId, 'portal_image', imageUrl));
                loadedImages.push(shopId);
            }
        };

        if (!singleShop) {
            for (const shopId of shopIds) {
                const shopData = allShops.find(s => s.shop_id === shopId);
                if (shopData?.portal_image.length === 0) {
                    getShopItemImage(shopId);
                }
            }

            // Client specific
            if (client) {
                for (const shop of allShops) {
                    if (shop.portal_image.length === 0 && shop.type !== 'Marketing' && shop.client_name === client.client_name) {
                        getShopItemImage(shop.shop_id);
                    }
                }
            }
        }
    }, []);

    const onRemoveShopPortal = useCallback((shopId) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        onRemoveShop(shopId);
    }, [onRemoveShop]);

    const onMoveShopPortal = useCallback((shopId, moveUp = true) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        onMoveShop(shopId, moveUp);
    }, []);

    const onAddShopImage = useCallback(async (imageData, shopId) => {
        // console.log(imageData, 'imageData');
        await oauth('PUT', `shop/${shopId}`, { portal_image: imageData.file_name_original });
        // Update state
        dispatch(finalizeUpdateShopFlatArray(shopId, 'portal_image', imageData.file_name_original));
    }, [dispatch]);

    const onUploadShopImageFile = useCallback((shopId) => (files) => {
        dispatch(createUploadFile('SHOP', 'PORTAL', files[0])).then(
            action => onAddShopImage(action.payload.data, shopId)
        );
    }, [dispatch, onAddShopImage]);

    // Categories
    let categories = getCategoriesFromShops(shopIds, allShops, singleShop, client ? client.client_name : false, showClientSpecific);
    if (singleShop) {
        categories = getCategoriesFromSingleShop(singleShopData, shopIds[0]);
    }
    const blocks = categories.map((category, index) => <PortalCategory
        themeColor={themeColor}
        singleShopId={shopIds[0]}
        key={category.id}
        category={category}
        sortFirst={index === 0}
        sortLast={index === categories.length - 1}
        isLast={(!isEditable || singleShop) && index === categories.length - 1}
        onUploadFile={onUploadShopImageFile(category.id)}
        onRemoveShop={onRemoveShopPortal(category.id)}
        onMoveUp={onMoveShopPortal(category.id, true)}
        onMoveDown={onMoveShopPortal(category.id, false)}
        isEditable={isEditable}
        client={client}
        blur={blur}
    />);

    // Edit button
    let heroEditButton = <Button secondary onClick={() => { setEditingHero(false); }} style={{padding: '0.5em 1em'}}>Done Editing</Button>;
    if (!editingHero) {
        heroEditButton = <Button secondary onClick={() => { setEditingHero(true); }} style={{padding: '0.5em 1em'}}>Edit</Button>;
    }

    const clientSpecificShopToggle = (
        <LabeledCheckbox
            label="Show Existing Client Shops"
            checked={showClientSpecific == 1}
            onChange={onToggleClientSpecificShop}
        />
    );

    const marginTop = isEditable ? '-30px' : '-15px';

    return (
        <div id="home" className="resku">
            <div id="hero-banner" style={{ marginTop: marginTop }} className={`shop ${blur ? 'blur-all' : ''}`}>
                {isEditable && <Row>
                    <Col sm={12} style={{ marginTop: '1.5em', marginBottom: '1em'}}>
                        <p><span style={{ fontWeight: 'bold' }}>Portal Logo</span></p>
                        <PortalLogo image={portalLogo} onLogoChange={onLogoChange} />
                    </Col>
                </Row>}
                {isEditable && !singleShop && <div style={{ marginTop: '1.5em', marginBottom: '1em' }}>
                    <div style={{float: 'right', marginTop: '-10px', marginRight: '10px'}}>
                        {heroEditButton}
                    </div>
                    <span style={{ fontWeight: 'bold'}}>Portal Images</span>
                </div>}
                {editingHero && !singleShop && <Row>
                    <Col sm={12}>
                        <PortalImages onAddImage={onAddImage} onRemoveImage={onRemoveImage} onMoveImage={onMoveImage} images={bannerImages} />
                    </Col>

                </Row>}
                {(!editingHero || singleShop) && <ImageGallery
                    smaller={isEditable}
                    items={images}
                    lazyLoad={false}
                    infinite={true}
                    showBullets={false}
                    showFullscreenButton={false}
                    autoPlay={true}
                    showPlayButton={false}
                    showThumbnails={false}
                    showIndex={false}
                    showNav={true}
                    fillImage={true}
                    slideInterval={6000}
                    onClick={() => {
                        console.log('clicked portal hero image');
                    }}
                />}
                <div className="row">
                    <Row>
                        {isEditable && !singleShop && (
                            <Col sm={12} style={{ marginTop: '1.5em' }}>
                                <div style={{float: "right"}}>
                                    {clientSpecificShopToggle}
                                </div>
                                <p><span style={{ fontWeight: 'bold' }}>Portal Shops</span></p>
                            </Col>
                        )}
                        <Col sm={12}>
                            {blocks}
                            {isEditable && !singleShop && <div className={`product-column small-12 medium-4 columns end`}>
                                <PortalAddShop
                                    onAddShop={onAddShop}
                                    allShops={allShops.filter(s => shopIds.indexOf(s.shop_id) === -1)}
                                />
                            </div>}
                        </Col>
                    </Row>
                </div>
            </div>
            <svg id="svg-filter">
                <filter id="svg-blur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="8"></feGaussianBlur>
                </filter>
            </svg>
        </div>
    );
}