import React, { useEffect, useState, useCallback } from 'react';
import { portalSelectors, getPortals, deletePortal } from '../../redux/clientPortals';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@commonsku/styles';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Button, Popup } from '@commonsku/styles';
import { getPortalName } from '../helpers/ClientPortal';
import { oauth } from '../../utils';
import uniqBy from 'lodash/uniqBy';
import { AsyncSelect } from '../helpers';
import { toast, ToastContainer } from 'react-toastify';

export default function ClientPortals({ showPortalPopup, industry_options, tag_options, clients }) {
    const dispatch = useDispatch();
    const portalState = useSelector(state => state.clientPortals);
    const portals = portalSelectors.selectAll(portalState);
    const [previewClient, setPreviewClient] = useState(null);
    const [clientOptions, setClientOptions] = useState(clients.map(c => ({ label: c.client_name, value: c.client_id })));
    const [previewClientPortal, setPreviewClientPortal] = useState(null);
    const [loadingMissing, setLoadingMissing] = useState(false);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [portalToDelete, setPortalToDelete] = useState(null);

    // Async select to load options for clients
    const loadClients = useCallback(async (searchValue) => {
        const clients = await oauth('GET', 'company', {
            company_type: 'CLIENT',
            search: true,
            'max-results': 50,
            company_name: searchValue
        });
        const options = clients.json.companies.map(c => ({ label: c.client_name, value: c.client_id }));
        setClientOptions((oldOptions) => {
            const allOptions = [...oldOptions, ...options];
            return uniqBy(allOptions, 'value');
        });
        return options;
    }, []);

    // Add client to the list if we have client in the clients list.
    const loadClientById = useCallback(async (clientId) => {
        if (clientOptions.find(c => c.value === clientId)) return;
        // console.log('Load a single client since we couldnot find it in the list of clients');
        const client = await oauth('GET', 'company/get', {
            company_type: 'CLIENT',
            id: clientId
        });
        setClientOptions((oldOptions) => {
            const allOptions = [...oldOptions, { label: client.json.company.client_name, value: client.json.company.client_id }];
            return uniqBy(allOptions, 'value');
        });
    }, [clientOptions]);

    const getClientPortal = useCallback(async (clientId) => {
        const portalData = await oauth('GET', 'client-portal-admin/get-client-portal', { client_id: clientId });
        // console.log(portalData.json.portal, 'portal for client', clientId);
        setPreviewClientPortal(portalData.json.portal);
    }, []);

    const showDeleteClientPortal = useCallback((portal) => {
        setPortalToDelete(portal);
        setDeleteConfirmOpen(true);
    }, []);

    const deleteClientPortal = useCallback(async (portal) => {
        const deleted = await dispatch(deletePortal(portal));
        if (deleted) {
            toast.success("Removed successfully");
        } else {
            toast.error("Failed to remove portal...");
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPortals());
    }, [dispatch]);

    // Load all portal clients to the list
    useEffect(() => {
        async function loadMissingClients() {
            const clientsToLoad = [];
            portals.map(p => {
                if (p.target === 'client') {
                    if (!clients.find(c => c.client_id === p.target_value) && clientsToLoad.indexOf(p.target_value) === -1) {
                        clientsToLoad.push(p.target_value);
                    }
                }
            });

            for (const client of clientsToLoad) {
                await loadClientById(client);
            }
        }
        if (!loadingMissing) {
            setLoadingMissing(true);
            loadMissingClients();
        }
    }, [clients, loadClientById, loadingMissing, portals]);

    const portalList = portals.map((p) => {
        const portalName = getPortalName(portals, p.client_portal_id, clientOptions, tag_options, industry_options);
        const showDelete = p.target !== 'default' || portals.length === 1;
        return (
            <Box key={p.client_portal_id} className="table-row portal-row">
                <Row>
                    <Col sm={4}>
                        <span className="table-cell">{portalName}{p.label && p.label !== '' ? ` (${p.label})` : ''}</span>
                    </Col>
                    <Col sm={2}>
                        <span className="table-cell"><a href="/clients" onClick={(e) => {
                            e.preventDefault();
                            showPortalPopup(p, 2);
                        }}>{p.client_count} clients</a></span>
                    </Col>
                    <Col sm={2}>
                        <span className="table-cell"><a href="/clients" onClick={(e) => {
                            e.preventDefault();
                            showPortalPopup(p, 1);
                        }}>{p.shops.split(',').length}</a></span>
                    </Col>
                    <Col sm={2}>
                        <span className="table-cell">{p.date_updated}</span>
                    </Col>
                    <Col sm={1}>
                        {showDelete && <span className="table-cell only-hover"><Button secondary onClick={() => showDeleteClientPortal(p)}>&times;</Button></span>}
                    </Col>
                    <Col sm={1}>
                        <span className="table-cell only-hover"><Button secondary onClick={() => showPortalPopup(p)}>Edit</Button></span>
                    </Col>
                </Row>
            </Box>
        );
    });

    let deletePortalPopup = null;
    if (deleteConfirmOpen && portalToDelete) {
        const portalToDeleteName = getPortalName(portals, portalToDelete.client_portal_id, clientOptions, tag_options, industry_options);
        deletePortalPopup = (
            <Popup header={<span></span>} style={{width: 'auto', height: 'auto'}}>
                <p>Are you sure you want to delete client portal: <b>{portalToDeleteName}</b>?</p>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '1em'}}>
                    <Button style={{marginRight: '1em'}} secondary onClick={() => { setDeleteConfirmOpen(false); }}>Cancel</Button>
                    <Button onClick={() => { deleteClientPortal(portalToDelete); setDeleteConfirmOpen(false); }}>Delete</Button>
                </div>
            </Popup>
        );
    }
    return (
        <>
            <Row>
                <Col sm={12}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div className="select" style={{ marginRight: '1em', paddingTop: '1em', flexGrow: 1, maxWidth: '300px' }}><AsyncSelect
                        placeholder="Search for client to preview..."
                        value={previewClient?.value}
                        options={clientOptions}
                        onChange={(option) => {
                            setPreviewClient(option);
                            getClientPortal(option.value);
                        }}
                        inPopup
                        loadOptions={loadClients}
                    />
                    </div>
                    <Button style={{padding: '0.45em 1em'}} disabled={!previewClientPortal} onClick={() => {
                            window.open(`/client/${previewClient.value}/portal/`, '_blank');
                        }}>Preview
                    </Button>
                    {previewClientPortal && (<>
                        <span style={{marginLeft: '1em'}}>{`${previewClient?.label} uses the `}{' '}
                            <b>{getPortalName(portals, previewClientPortal?.client_portal_id, clientOptions, tag_options, industry_options, true)}</b>
                        </span>
                        <span style={{ marginLeft: '1em' }}><a target="_blank" href="https://vandelaypromotions.commonsku.com/video.html?src=https%3A%2F%2Ffiles.commonsku.com%2Fzoom_recording%2F89444248643%2F2d1761f4-1728-47a2-8f10-38b44d3c432b.shared_screen_with_speaker_view.mp4"><b>Help</b></a></span>
                    </>)}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Row style={{ background: '#dae9ee' }}>
                        <Col sm={4}>
                            <span className="table-header">Portal</span>
                        </Col>
                        <Col sm={2}>
                            <span className="table-header">Who uses it?</span>
                        </Col>
                        <Col sm={2}>
                            <span className="table-header">Shops</span>
                        </Col>
                        <Col sm={3}>
                            <span className="table-header">Last Updated</span>
                        </Col>
                    </Row>
                    {portalList}
                </Col>
            </Row>
            {deletePortalPopup}
            <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    );
}
