import React, { useCallback, useState } from 'react';
import { Select } from '@commonsku/styles';
import 'react-toastify/dist/ReactToastify.css';

export default function PortalAddShop({ onAddShop, allShops }) {
    const [addShopValue, setAddShopValue] = useState(null);

    const marketingShops = allShops.filter(s => {
        if (s.type === 'Marketing') {
            if (s.status_name === 'Closed') return false;
            if (s.date_expiry) {
                const expired = Date.parse(s.date_expiry);
                if (Date.now() > expired ) return false;
            }
            return true;
        }
        return false;
    });

    const onNewShopAdded = useCallback((shopId) => {
        onAddShop(marketingShops.find(shop => shop.shop_id === shopId));
    }, [marketingShops, onAddShop]);

    const shopOptions = marketingShops.map(shop => ({ label: shop.shop_name, value: shop.shop_id }));

    if (shopOptions.length === 0) return null;

    return (
        <>
            <Select
                placeholder="Add a new shop..."
                name="add_shop"
                value={addShopValue}
                options={shopOptions}
                onChange={(option) => { onNewShopAdded(option.value); }}
                inPopup
            />
        </>
    );
}